import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';

import { useProducts } from '#mrktbox';
import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Container from '#materials/Container';

import ProductGrid from '#components/products/ProductGrid';
import ProductCard from '#components/products/ProductCard';
import SearchBar from '#components/catalogue/SearchBar';

interface Style {
  theme? : Theme;
}

const SearchText = styled.p<Style>`
  display: block;
  width: fit-content;
  margin: auto;

  color: ${(props) => props.theme.palette.background.text.primary};

  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.large};
  font-family: ${(props) => props.theme.typography.fonts.brand.family};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes.medium};
  }
`;

const Search = () => {
  const { retrieveProductsBulk } = useProducts();
  const { filterProducts } = useCatalogue();

  const [input, setInput] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  useEffect(() => {
    if (searchResults.length > 0) {
      retrieveProductsBulk(
        searchResults
          .filter(p => p.id !== undefined)
          .map(product => product.id) as number[]
      );
    }
  }, [searchResults, retrieveProductsBulk]);

  return (
    <>
      <SearchBar
        input={input}
        setInput={setInput}
        setProducts={setSearchResults}
      />
      <Container style={{marginBottom: '2rem'}}>
        { searchResults.length > 0
          ? (
            <ProductGrid>
              { filterProducts(searchResults).map(product => (
                <ProductCard
                  key={product.id}
                  product={product}
                />
              ))}
            </ProductGrid>
          ) : (
            <SearchText>
              { input
                ? "Huh, can't seem to find what you're looking for."
                : "Just start typing in the search bar to search!"
              }
            </SearchText>
          )
        }
      </Container>
    </>
  );
}

export default Search;
