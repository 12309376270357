import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';
import useCatalogue from '#hooks/useCatalogue';

import Preface from '#materials/Preface';
import Heading from '#materials/Heading';
import BackButton from '#materials/BackButton';
import CartButton from '#materials/CartButton';
import NavButton from '#materials/NavButton';
import { ChevronDown, ChevronUp, Grid, Search } from '#materials/icons';

import Header from '#components/header/Header';
import CategoryDropdown from '#components/catalogue/CategoryDropdown';
import OrderOptionsDropdown from '#components/catalogue/OrderOptionsDropdown';
import OrderTime from '#components/orders/OrderTime';
import Cart from '#components/cart/Cart';
import Nav from '#components/nav/Nav';

interface Style {
  theme? : Theme;
}

interface HeaderNameStyle extends Style {
  large? : boolean;
}

interface CategoriesStyle extends Style {
  showCategories?: boolean;
}

const CatalogueHeaderTitleServiceType = styled(Preface)<Style>`
  display: block;
  margin: 0.5rem 0 0;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(props) => props.theme.palette.background.text.primary };
  font-size: ${(props) => props.theme.typography.fonts.title.sizes.xsmall};
  line-height: ${(props) => props.theme.typography.fonts.title.lineHeight};


  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    text-align: left;
    font-size: ${(props) => props.theme.typography.fonts.title.sizes.xxsmall};
  }
`;

const CatalogueHeaderTitleRevenueCenter = styled.button<Style>`
  display: flex;
  width: 100%;
  margin: 0.5rem 0 0;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary };
  font-family: ${(props) => props.theme.typography.fonts.brand.family};

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover };
  }

  > span {
    display: inline-block;
    color: inherit;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    justify-content: flex-start;
    text-align: left;
  }
`;

const CatalogueHeaderName = styled.span<HeaderNameStyle>`
  max-width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    color: inherit;
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes.large};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size: ${(props) => props.large
        ? props.theme.typography.fonts.brand.sizes.large
        : props.theme.typography.fonts.brand.sizes.medium};
    }
  }
`;

const CatalogueHeading = styled(Heading)<Style>`
  line-height: ${(props) => props.theme.typography.fonts.brand.lineHeight};
  font-family: ${(props) => props.theme.typography.fonts.brand.family};
`;

const CatalogueHeaderDropdown = styled.span<HeaderNameStyle>`
  margin: -0.2 0 0 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
  flex-shrink: 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: ${(props) => props.large ? '1.6rem' : '1.2rem'};
    height: ${(props) => props.large ? '1.6rem' : '1.2rem'};
  }
`;

const Categories = styled.button<CategoriesStyle>`
  display: flex;
  height: inherit;
  padding: 0 1rem;
  ${(props) => props.showCategories && 'margin-bottom: -1px;'}
  margin-left: ${(props) => props.theme.layout.spacing.small};
  align-items: center;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.showCategories
    ? props.theme.palette.background.fill
    : 'transparent'
  };
  border-left: 1px solid ${(props) => props.theme.palette.border};
  border-right: 1px solid ${(props) => props.theme.palette.border};
  border-color: ${(props) => props.showCategories
    ? props.theme.palette.border
    : 'transparent'
  };

  font-size: ${props => props.theme.typography.fonts.brand.sizes.large};
  font-family: ${props => props.theme.typography.fonts.brand.family};

  transition: background-color, color .2s ease;

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin-left: 0;
    font-size: 0;
  }
`;

const SearchButton = styled(Link)<Style>`
  display: flex;
  margin: 0.2rem 0 0;
  padding: 0.5rem ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: 'transparent';
  border: 2px solid ${(props) => props.theme.palette.background.text.primary};
  border-radius: 2rem;

  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.medium};
  text-decoration: none;

  svg {
    height: 1.6rem;
    width: 1.6rem;
    stroke-width: 2.5;
    vertical-align: bottom;
  }

  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
    border-color: ${(props) => props.theme.palette.background.text.hover};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding: 0;
    border: none;
    font-size: 0;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

interface CatalogueHeaderTitleProps {
  showOptions : boolean;
  setShowOptions? : (show : boolean) => void;
  onClick? : () => void;
}

function CatalogueHeaderTitle({
  showOptions,
  setShowOptions,
  onClick,
} : CatalogueHeaderTitleProps) {
  const { brand } = useConfig();
  const { open, openModal } = useModal();
  const { serviceChannel, address, location, time, cutoff } = useRequests();

  const [timeCheck, setTimeCheck] = useState<boolean>(false);
  const [delayed, setDelayed] = useState<boolean>(false);

  const toggle = useCallback((evt : React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (setShowOptions) setShowOptions(!showOptions);
    if (onClick) onClick();
  }, [showOptions, setShowOptions, onClick]);

  useEffect(() => {
    if (timeCheck) return;
    setTimeCheck(true);
    setTimeout(() => { setDelayed(true) }, 2000);
  }, [timeCheck]);

  useEffect(() => {
    if (!delayed) return;
    setDelayed(false);

    if (
      !serviceChannel
        || (!address && !location)
        || (time && (!cutoff || (cutoff > new Date())))
        || open
    ) return;
    openModal(<OrderTime />);
  }, [
    delayed,
    serviceChannel,
    address,
    location,
    time,
    cutoff,
    open,
    openModal,
  ]);

  return (
    <>
      { serviceChannel && (
        <CatalogueHeaderTitleServiceType>
          { serviceChannel.name }
        </CatalogueHeaderTitleServiceType>
      ) }
      <CatalogueHeaderTitleRevenueCenter onClick={toggle}>
        <CatalogueHeaderName large={!serviceChannel}>
          <CatalogueHeading>
            { (location?.name ?? brand.name ).toUpperCase() }
          </CatalogueHeading>
        </CatalogueHeaderName>
        <CatalogueHeaderDropdown large={!serviceChannel}>
          { showOptions ? <ChevronUp /> : <ChevronDown /> }
        </CatalogueHeaderDropdown>
      </CatalogueHeaderTitleRevenueCenter>
    </>
  );
}

function CatalogueHeader() {
  const { open: isSidebarOpen, openSidebar } = useSidebar();
  const { last, setLast } = useCatalogue();
  const { cartCount, resetProposedChanges } = useRequests();

  const [showCategories, setShowCategories] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleCategories = useCallback(() => {
    setShowCategories(!showCategories);
    setShowOptions(false);
  }, [showCategories, setShowCategories]);

  const handleToggleShowOptions = useCallback(() => {
    if (!showOptions) resetProposedChanges();
    setShowOptions(!showOptions);
    setShowCategories(false);
  }, [showOptions, setShowOptions, resetProposedChanges]);

  const closeAll = useCallback(() => {
    setShowCategories(false);
    setShowOptions(false);
  }, []);

  const handleBack = useCallback(() => {
    setLast('/');
    closeAll();
  }, [setLast, closeAll]);

  const handleCart = useCallback(() => {
    resetProposedChanges();
    openSidebar(<Cart />);
  }, [openSidebar, resetProposedChanges]);

  const handleNav = useCallback(() => {
    openSidebar(<Nav />);
  }, [openSidebar]);

  useEffect(() => {
    if (isSidebarOpen && (showCategories || showOptions)) closeAll();
  }, [isSidebarOpen, showCategories, showOptions, closeAll]);

  return (
    <>
      <Header
        title={
          <CatalogueHeaderTitle
            showOptions={showOptions}
            onClick={handleToggleShowOptions}
          />
        }
        left={(
          <>
            <BackButton
              path={last}
              onClick={handleBack}
            />
            <Categories
              onClick={handleToggleCategories}
              showCategories={showCategories}
            >
              <Grid size={24} />
              <span>&nbsp;Shop</span>
            </Categories>
          </>
        )}
        right={(
          <>
            <SearchButton to="/search" onClick={closeAll}>
              <Search strokeWidth={2} />
              <span>&nbsp;Search</span>
            </SearchButton>
            <CartButton onClick={handleCart} quantity={cartCount} />
            <NavButton onClick={handleNav} />
          </>
        )}
        border={showCategories || showOptions}
        style={
          (showCategories || showOptions)
            ? { boxShadow: 'none' }
            : undefined
        }
      />
      <CategoryDropdown
        open={showCategories}
        setOpen={setShowCategories}
      />
      <OrderOptionsDropdown
        show={showOptions}
        setShow={setShowOptions}
      />
    </>
  );
}

export default CatalogueHeader;
