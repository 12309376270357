import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import {
  useNavigation,
  useOptions,
  useSubscriptions,
  useNotes,
} from '#mrktbox';
import { LineItem, ProjectedOrder } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';
import useCatalogue from '#hooks/useCatalogue';

import ButtonLink from '#materials/ButtonLink';
import Heading from '#materials/Heading';
import Body from '#materials/Body';
import { AlertCircle, Refresh } from '#materials/icons';

import ProductImage from '#components/products/ProductImage';
import Quantity from '#components/cart/Quantity';

interface Style { theme? : Theme; }
interface CartItemDescriptionStyle extends Style { valid? : boolean; }

const CartItemView = styled.span<Style>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.theme.layout.spacing.xsmall} 0;
  border-bottom-style: solid;
  border-bottom-width: ${(props) => props.theme.layout.border.width};
  border-bottom-color: ${(props) => props.theme.palette.border};

  &:last-of-type {
    border: 0;
  }
`;

const CartItemImage = styled.div<Style>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: ${(props) => props.theme.layout.border.radius};
`;

const CartItemInfo = styled.span<Style>`
  display: block;
  width: 0;
  padding: 0 ${(props) => props.theme.layout.spacing.small};
  flex-grow: 1;

  overflow: hidden;
`;

const CartItemName = styled(Heading)`
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
`;

const CartItemDescription = styled(Body)<CartItemDescriptionStyle>`
  display: flex;
  max-width: 100%;
  max-height: 3.6rem;
  margin-top: ${(props) => props.theme.layout.spacing.xxsmall};
  gap: ${(props) => props.theme.layout.spacing.xxsmall};

  overflow: hidden;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};

  ${(props) => (props.valid === false) && `
    color: ${props.theme.palette.background.text.alert};
  `}
`

const CartItemDetails = styled.span<Style>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap : ${(props) => props.theme.layout.spacing.xxsmall};
  overflow: hidden;
`;

const CartItemPrice = styled(Heading)`
  display: block;
  white-space: nowrap;
  margin :
    ${(props) => props.theme.layout.spacing.xxsmall}
    ${(props) => props.theme.layout.spacing.xsmall}
    0 0;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }
`;

interface CartItemLinkStyle extends Style { direction? : 'row' | 'column'; }

const CartItemLink = styled.span<CartItemLinkStyle>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  ${(props) => (props.direction === 'column') && 'align-items: flex-start;'}
  flex-wrap: wrap;
  gap: ${(props) => (props.direction === 'column')
    ? '0'
    : props.theme.layout.spacing.xxsmall};
  overflow: hidden;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};

  a, button {
    margin:
      ${(props) => props.theme.layout.spacing.xxsmall}
      ${(props) => props.theme.layout.spacing.xsmall}
      0 0;
    text-align: left;
  }
`;

const SubscriptionLink = styled(CartItemLink)`
  * {
    margin-right: ${(props) => props.theme.layout.spacing.xxsmall};
  }
`

const CartItemQuantity = styled.div`
  display: flex;
  width: 9.2rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

interface CartItemProps {
  item : LineItem;
  order? : ProjectedOrder | null;
  isFulfilment? : boolean;
  setBusy? : (busy : boolean) => void;
}

function CartItem({
  item,
  order,
  isFulfilment,
  setBusy,
} : CartItemProps) {
  const { navigate } = useNavigation();
  const { closeSidebar } = useSidebar();
  const {
    calculateLinePrice,
    isProductCustomisable,
    validateSelections,
  } = useOptions();
  const {
    canProductHaveNote,
    doesProductRequireNote,
    getLineItemNotes ,
  } = useNotes();
  const { findLineItemSubscription } = useSubscriptions();
  const {
    serviceChannel,
    time,
    removeItem,
    updateItem,
    canUpdateItem,
    canSubscribe,
    evaluateReccurence,
    formatRecurrence,
  } = useRequests();
  const {
    allProducts : products,
    getProductSlug,
    isProductAvailable,
    isProductStocked,
  } = useCatalogue();

  const [subscribing, setSubscribing] = useState(false);
  const [newQty, setNewQty] = useState<number | null>(null);

  const selectionsValid = useMemo(() => {
    if (!order || !products) return true;

    const time = order.time ?? new Date();
    const product = products[item.productId];
    if (!product || !isProductCustomisable(product, time)) return true;

    const selections = Object.values(order.selections)
      .filter((s) => s.lineItemId === item.id);
    return validateSelections(product, selections, time).valid;
  }, [order, products, item, isProductCustomisable, validateSelections]);

  const product = products?.[item.productId] ?? null;
  const selections = order?.selections
    ? Object.values(order.selections).filter((s) => s.lineItemId === item.id)
    : [];
  const subscription = order ? findLineItemSubscription(item, order) : null;
  const recurring = order ? evaluateReccurence(
    subscription,
    { iteration : order.timeSlotIteration },
  ) : 0;
  const [deleting, setDeleting] = useState(false);

  const quantity = subscription?.quantity ?? item.quantity;

  const handleRemove = useCallback(() => {
    setNewQty(null);
    if (isFulfilment) removeItem(item, { isFulfilment : true });
    else if (recurring) setDeleting(true);
    else if (subscription) removeItem(item, { target : 'future' });
    else removeItem(item);
  }, [item, isFulfilment, subscription, recurring, removeItem]);

  const handleRemoveSubscription = useCallback((
    target : 'this' | 'future',
  ) => async () => {
    const success = await removeItem(item, { target });
    if (success) setDeleting(false);
  }, [item, removeItem]);

  const handleSetQuantity = useCallback((qty : number) => {
    if (qty < 1) handleRemove();
    else if (recurring) setNewQty(qty);
    else if (quantity === qty) return;
    else if (subscription) {
      updateItem(item, { quantity: qty }, undefined, { target : 'this' });
    }
    else updateItem(item, { quantity: qty });
  }, [item, quantity, recurring, subscription, handleRemove, updateItem]);

  const handleIncrement = useCallback((count : number) => () => {
    handleSetQuantity((newQty ?? quantity) + count);
  }, [quantity, newQty, handleSetQuantity]);

  const handleUpdateSubscription = useCallback((
    target : 'this' | 'future',
  ) => async () => {
    if (newQty === null) return;
    const success = await updateItem(
      item,
      { quantity : newQty },
      undefined,
      { target },
    );
    if (success) setNewQty(null);
  }, [item, newQty, updateItem]);

  const handleEdit = useCallback(() => {
    closeSidebar();
    const slug = product ? getProductSlug(product) : item.productId;
    navigate(`/product/${slug}/?lineItemId=${item.id}`);
  }, [item, product, getProductSlug, closeSidebar, navigate]);

  const handleSubscribe = useCallback((period : number) => async () => {
    const success = await updateItem(
      item,
      undefined,
      undefined,
      { period, target : 'future' },
    );
    if (success) setSubscribing(false);
  }, [item, updateItem]);

  useEffect(() => {
    if (setBusy) setBusy(subscribing || deleting || !!newQty);
  }, [subscribing, deleting, newQty, setBusy]);

  const listPrice = calculateLinePrice(item, order, { adjustments : [] });
  const price = calculateLinePrice(item, order);

  const available = !!product && isProductAvailable(product);
  const inStock = !!product && isProductStocked(product);

  const canUpdateLine = canUpdateItem(item);
  const canUpdate = !isFulfilment && canUpdateLine;
  const canRemove = canUpdateLine;
  const removableFulfilment = isFulfilment && canRemove;

  const selectionsDescription = selections.map((s) => {
    if (!products) return null;
    const f = order?.order
      ? Object.values(order.order.fulfilments).find((f) => (
        f.lineItemId === s.lineItemId
          && f.requestedProductId === s.productId
      )) : undefined;
    const productId = f
      ? (f.fulfilledProductId ?? f.requestedProductId)
      : s.productId;
    return (products[productId]?.name ?? null) + ((s.quantity > 1)
      ? ` (${s.quantity})` : '');
  }).filter((s) => s !== null).join(', ');
  const requiresNote = product && doesProductRequireNote(product);
  const fullNote = getLineItemNotes(item).map((n) => n.content).join(' ');
  const note = (fullNote.length > 100)
    ? fullNote.slice(0, 100) + '...'
    : fullNote;
  const description = !available
    ? 'Product Not Available for ' + (serviceChannel?.name || 'Order Type')
    : (!inStock
      ? 'Out of Stock'
      : ((requiresNote && !fullNote)
        ? 'Note required'
        : (removableFulfilment
          ? 'Opps! Something went wrong with this item'
          : (selectionsValid
            ? selectionsDescription
            : 'Additional Customisation Required'))));

  const valid = selectionsValid
    && available
    && inStock
    && (!requiresNote || !!fullNote)
    && !removableFulfilment;

  const subscribable = canSubscribe();
  const subscriptionText = formatRecurrence(recurring);

  const specialPrice = price?.amount !== listPrice?.amount;
  const showDescription = !subscribing && !deleting && !newQty;

  return (
    <CartItemView id={`line-item-${item.id}`}>
      <CartItemImage>
        <ProductImage
          product={product}
          variant="thumbnail"
          fade={!available || !inStock}
        />
      </CartItemImage>
      <CartItemInfo>
        <CartItemName>{ product?.name }</CartItemName>
        { showDescription && (
          <>
            { description && (
              <CartItemDescription valid={valid}>
                { !valid && (
                  <AlertCircle size={14} />
                ) }
                { description }
              </CartItemDescription>
            ) }
            { note && (
              <CartItemDescription>{ note }</CartItemDescription>
            ) }
            <CartItemDetails>
              <CartItemPrice>
                { (!specialPrice && price) ? formatCurrency(price) : '' }
                { (specialPrice && price && listPrice) && (
                  <>
                    <s>{ formatCurrency(listPrice) }</s>
                    <span>{ ' ' + formatCurrency(price) }</span>
                  </>
                ) }
              </CartItemPrice>
              { (canUpdate || canRemove) && (
                <CartItemLink>
                  { (product
                    && canUpdate
                    && (canProductHaveNote(product)
                      || isProductCustomisable(product, time ?? new Date()))
                  ) && (
                    <ButtonLink onClick={handleEdit}>edit</ButtonLink>
                  ) }
                  { canRemove && (
                    <ButtonLink onClick={handleRemove}>remove</ButtonLink>
                  ) }
                </CartItemLink>
              ) }
            </CartItemDetails>
          </>
        ) }
        { subscribing && (
          <>
            <CartItemDescription>
              How often would you like your order?
            </CartItemDescription>
            <CartItemLink direction='column'>
              { !!recurring && (
                <ButtonLink onClick={handleSubscribe(0)}>
                  one time
                </ButtonLink>
              ) }
              { [1, 2, 3, 4].map((period) => (
                  <ButtonLink
                    key={period}
                    disabled={recurring === period}
                    onClick={handleSubscribe(period)}
                  >
                    { formatRecurrence(period) }
                  </ButtonLink>
                ))
              }
              <ButtonLink onClick={() => setSubscribing(false)}>
                cancel
              </ButtonLink>
            </CartItemLink>
          </>
        ) }
        { deleting && (
          <CartItemDetails>
            <CartItemDescription>
              Delete subscription, or just from this order?
            </CartItemDescription>
              <CartItemLink>
                <ButtonLink  onClick={handleRemoveSubscription('this')}>
                  this order
                </ButtonLink>
                <ButtonLink onClick={handleRemoveSubscription('future')}>
                  this & following orders
                </ButtonLink>
                <ButtonLink onClick={() => setDeleting(false)}>
                  cancel
                </ButtonLink>
              </CartItemLink>
          </CartItemDetails>
        ) }
        { newQty !== null && (
          <CartItemDetails>
            <CartItemDescription>
              Update subscription, or just from this order?
            </CartItemDescription>
            <CartItemLink>
              <ButtonLink onClick={handleUpdateSubscription('this')}>
                this order
              </ButtonLink>
              <ButtonLink onClick={handleUpdateSubscription('future')}>
                this & following orders
              </ButtonLink>
              <ButtonLink onClick={() => setNewQty(null)}>cancel</ButtonLink>
            </CartItemLink>
          </CartItemDetails>
        ) }
        <SubscriptionLink>
          { (
            showDescription
              && subscribable
              && (recurring || (canUpdate
                && inStock
                && available))
          ) && (
            <ButtonLink
              onClick={() => setSubscribing(true)}
              disabled={!canUpdate}
            >
              { recurring
                ? (<Refresh size={8} />)
                : null
              }
              { recurring ? subscriptionText : 'subscribe' }
            </ButtonLink>
          ) }
        </SubscriptionLink>
      </CartItemInfo>
      <CartItemQuantity>
        <Quantity
          id={`quantity-${item.productId}`}
          label={`${product?.name ?? 'Product'} Quantity`}
          count={newQty ?? quantity}
          setCount={handleSetQuantity}
          increment={handleIncrement(1)}
          decrement={handleIncrement(-1)}
          incrementDisabled={subscribing
            || deleting
            || !canUpdate
            || !inStock
            || !available
          }
          decrementDisabled={subscribing
            || deleting
            || !canUpdate
            || !inStock
            || !available
          }
        />
      </CartItemQuantity>
    </CartItemView>
  );
}

export default CartItem;
