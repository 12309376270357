import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import * as Sentry from '@sentry/react';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageContent from '#components/page/PageContent';
import PageTitle from '#components/page/PageTitle';
import Header from '#components/header/Header';

interface Style {
  theme? : Theme;
}

const ErrorReportView = styled.div<Style>`
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.medium} 0;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.small} 0;
  }
`

const ErrorButtons = styled.div<Style>`
  width: fit-content;
  margin: 0 auto;
`;

const ErrorReportDetails = styled.div<Style>`
  margin: ${(props) => props.theme.layout.spacing.medium} 0;
  padding: ${(props) => props.theme.layout.spacing.small};

  color: ${(props) => props.theme.palette.notifications.alert.text};
  background-color: ${(props) => props.theme.palette.notifications.alert.fill};

  border-radius: ${(props) => props.theme.layout.border.radius};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.small} 0;
  }

  pre {
    margin: 0;
    overflow: hidden;
    white-space: pre-wrap;
    font-family: ${(props) => props.theme.typography.fonts.mono.family};
    font-size: ${(props) => props.theme.typography.fonts.mono.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.mono.lineHeight};
    text-align: left;
  }
`;

interface ErrorReportProps {
  error? : Error;
  errorInfo? : React.ErrorInfo;
  eventId? : string;
}

function ErrorReport({
  error,
  errorInfo,
  eventId,
} : ErrorReportProps) {
  const { brand, content } = useConfig();

  const handleReset = useCallback(() => {
    // TODO: reset state
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          { content.error.title} | {brand.title }
        </title>
      </Helmet>
      <Content hasRouter={false}>
        <Header />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...content.error} />
            <PageContent>
              <ErrorButtons>
                <ButtonStyled onClick={handleReset}>Start Over</ButtonStyled>
              </ErrorButtons>
              <ErrorReportView>
                { eventId && (
                  <p>
                    <ButtonLink
                      onClick={() => Sentry.showReportDialog({ eventId })}
                    >
                      Click here to report this issue
                    </ButtonLink>
                  </p>
                ) }
                <ErrorReportDetails>
                  <pre>
                    { error ? error.toString() : '' }
                    { errorInfo && errorInfo?.componentStack?.toString() }
                  </pre>
                </ErrorReportDetails>
              </ErrorReportView>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default ErrorReport;
