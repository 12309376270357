import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface CheckSummaryViewStyle extends Style {
  updating? : boolean;
}

const SummaryView = styled.div<CheckSummaryViewStyle>`
  position: relative;
  width: calc(100% - ${(props) => props.theme.layout.spacing.medium} * 2);
  z-index: 2;
  margin: 1rem ${(props) => props.theme.layout.spacing.medium} 1rem;
  padding: 0;
  overflow: hidden;

  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.border};
  border-top-width: ${(props) => props.theme.layout.border.width};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: calc(100% - ${(props) => props.theme.layout.spacing.xsmall} * 2);
    margin: 1rem ${(props) => props.theme.layout.spacing.xsmall} 1rem;
  }

  @media (max-height: 600px) {
    height: 0;
  }

  & > li {
    opacity: ${(props) => (props.updating ? '0.5' : '1')};
  }
`;

const SummaryTotalView = styled.div<Style>`
  display: flex;
  width: 24rem;
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    0 0
    calc(100% - 24rem);
  padding: ${(props) => props.theme.layout.spacing.small} 0 0;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.palette.background.text.primary};

  border-style: solid;
  border-width: 0;
  border-top-width: ${(props) => props.theme.layout.border.width};
  border-color: ${(props) => props.theme.palette.border};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  font-weight: ${(props) => props.theme.typography.fonts.default.weights.bold};
  text-align: right;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 100%;
    margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }

  span {
    display: block;
  }
`;

interface TotalsProps {
  total? : Currency;
  children? : React.ReactNode;
}

function Totals({
  total,
  children,
} : TotalsProps) {
  return (
    <SummaryView>
      { children }
      <SummaryTotalView>
        <span>Total</span>
        { total && <span>{ formatCurrency(total) }</span> }
      </SummaryTotalView>
    </SummaryView>
  );
}

export default Totals;
